import React, { useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { EducationSave } from "./EducationSave";
import Autosuggest from "react-autosuggest";
import { EducationContext } from "./EducationContext";
import ProfileDataService from '../../../../services/individual/UserProfile';

/* education modal */
export function EducationEditModal(props) {
    const { institute, course, isOnGoing, durationFrom, durationTo, specialization,type } = props.eduItem;
    const [instituteName, setInstituteName] = useState(institute?.title || '');
    const [courseName, setCourseName] = useState(course.title);
    const [specializationName, setSpecializationName] = useState(specialization?.title);
    const [suggestions, setSuggestions] = useState([]);

    // useEffect(() => {
    //     const input = {
    //       start: 0,
    //       length: 100,
    //     }

    //     ProfileDataService.fetchIndustires(input)
    //       .then(response => {
    //         console.log("industriesObj=", response)
    //         setindustries(response.data.data);
    //       })

    //   }, [])

    function getInstituteList(val) {
        console.log("instituteName::", instituteName)
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title,location,instituteId,profilePicture',
            // 'orderBy[column]': 'instituteId',
            // 'orderBy[dir]': 'desc'
        }
        const data = ProfileDataService.fetchInstituteData(input);
        return data;
    }

    function getCourseList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title',
        }
        const data = ProfileDataService.fetchCourseeData(input);
        return data;
    }

    function getSpecializationList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title,courseId',
        }
        const data = ProfileDataService.fetchSpecializationData(input);
        return data;
    }
    const getEDUmodalData= {...props.eduItem,instituteName,courseName,specializationName,job_type:type};

    return (
        <EducationContext.Consumer>
            {({ onEditEduHandler, editEduSuggesions, industries }) => (
                <Modal className="edit-modals" show={props.show} onHide={props.handleClose} centered size="md" backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Education Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                School/College/University <span className="text-danger">*</span>
                            </Form.Label>
                            <Autosuggest
                                inputProps={{
                                    placeholder: "Institute Name",
                                    autoComplete: "off",
                                    name: "institute",
                                    id: "institute",
                                    value: instituteName,
                                    onChange: (e, { newValue }) => {
                                        setInstituteName(newValue);
                                        // Add this to handle manual institute entries during edit
                                        editEduSuggesions({
                                            id: 0,
                                            title: newValue,
                                            profilePicture: ''
                                        }, props.index, "institute");
                                    }
                                }}
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={
                                    async ({ value }) => {
                                        if (value === '') {
                                            setSuggestions([]);
                                            return;
                                        }
                                        try {
                                            const result = await getInstituteList(value)
                                            setSuggestions(
                                                result.data.data.map((item) => ({
                                                    title: item.title,
                                                    id: item.id
                                                }))
                                            );
                                            console.log('This is ', result.data.data);
                                        } catch (e) {
                                            console.log(e)
                                            setSuggestions([]);
                                        }
                                    }
                                }

                                onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                onSuggetionSelected={(event, { suggestion, method }) => {
                                    if (method === "enter")
                                        event.preventDefault();
                                }}
                                getSuggestionValue={(suggestion) => {
                                    editEduSuggesions(suggestion, props.index, "institute");
                                    return suggestion.title
                                }}
                                renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Degree<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Autosuggest
                                        inputProps={{
                                            placeholder: "Degree Name",
                                            autoComplete: "off",
                                            name: "course",
                                            id: "course",
                                            value: courseName,
                                            onChange: (e, { newValue }) => {
                                                setCourseName(newValue)
                                            }
                                        }}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={
                                            async ({ value }) => {
                                                if (value === '') {
                                                    setSuggestions([]);
                                                    return;
                                                }
                                                try {
                                                    const result = await getCourseList(value)
                                                    setSuggestions(
                                                        result.data.data.map((item) => ({
                                                            title: item.title,
                                                            id: item.id
                                                        }))
                                                    );
                                                    console.log('This is course list', result.data.data);
                                                } catch (e) {
                                                    console.log(e)
                                                    setSuggestions([]);
                                                }
                                            }
                                        }

                                        onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                        onSuggetionSelected={(event, { suggestion, method }) => {
                                            if (method === "enter")
                                                event.preventDefault();
                                        }}
                                        getSuggestionValue={(suggestion) => {
                                            editEduSuggesions(suggestion, props.index, "course");
                                            return suggestion.title
                                        }}
                                        renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Field of study<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Autosuggest
                                        inputProps={{
                                            placeholder: "Specialization",
                                            autoComplete: "off",
                                            name: "specialization",
                                            id: "specialization",
                                            value: specializationName,
                                            onChange: (e, { newValue }) => {
                                                setSpecializationName(newValue)
                                            }
                                        }}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={
                                            async ({ value }) => {
                                                if (value === '') {
                                                    setSuggestions([]);
                                                    return;
                                                }
                                                try {
                                                    const result = await getSpecializationList(value)
                                                    setSuggestions(
                                                        result.data.data.map((item) => ({
                                                            title: item.title,
                                                            id: item.id,
                                                            courseId: item.courseId
                                                        }))
                                                    );
                                                    console.log('This is specialization list', result.data.data);
                                                } catch (e) {
                                                    console.log(e)
                                                    setSuggestions([]);
                                                }
                                            }
                                        }

                                        onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                        onSuggetionSelected={(event, { suggestion, method }) => {
                                            if (method === "enter")
                                                event.preventDefault();
                                        }}
                                        getSuggestionValue={(suggestion) => {
                                            editEduSuggesions(suggestion, props.index, "specialization");
                                            return suggestion.title
                                        }}
                                        renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Education Type<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        custom
                                        name="type"
                                        id="type"
                                        // onChange={props.childWEToCall.onEditWEHandler}
                                        onChange={(e)=>editEduSuggesions(e.target.value, props.index, e.target.name)}
                                        value={type || 1}
                                    >
                                        <option value="1">Full time</option>
                                        <option value="2">Part time</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Start year<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="durationFrom"
                                        id="durationFrom"
                                        onChange={event => editEduSuggesions(event.target.value, props.index, event.target.name)}
                                        value={durationFrom || ""}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>End year</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="durationTo"
                                        id="durationTo"
                                        disabled={isOnGoing ? true : false}
                                        onChange={event => editEduSuggesions(event.target.value, props.index, event.target.name)}
                                        value={durationTo || ""}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    {["checkbox"].map((type) => (
                                        <div key={`custom-${type}`}>
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="isOnGoing"
                                                name="isOnGoing"
                                                label="I currently study here"
                                                onChange={event => editEduSuggesions(event.target.checked, props.index, event.target.name)}
                                                checked={isOnGoing || false}
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-center pt-3">
                            <EducationSave closeMain={props.handleClose} EDUmodalData={getEDUmodalData} />
                        </div>

                    </Modal.Body>
                </Modal>
            )}
        </EducationContext.Consumer>
    );
}
