import React, { useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import WorkExperienceSave from "./WorkExpereienceSave";
import { WorkExperienceContext } from "./WorkExperienceContext";
import ProfileDataService from '../../../../services/individual/UserProfile';
import Autosuggest from "react-autosuggest";
import PlacesAutocomplete from 'react-places-autocomplete';
import { placeGoogle } from "./googleAddrCall";
import { functionsIn } from "lodash";

/* work expreince modal */
export default function WorkExpereienceModal(props) {
    // const { locality, city, state, country } = props.weItem;
    // const fullAddress = [
    //     locality,
    //     city?.name,
    //     state?.name,
    //     country?.name
    // ].filter(Boolean).join(", ");
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [companyName, setcompanyName] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [weDesignation, setweDesignation] = useState("");
    const [addressLoc, setAddressLoc] = useState("");
    const [weIndustry, setweIndustry] = useState("");
    const clearWEData=()=>{
        const resetWorkExperienceData = props.childWEToCall.resetEditWEValues
        setcompanyName("");
        setweDesignation("");
        setAddressLoc("");
        setweIndustry("");
        resetWorkExperienceData();
    }
    function onSave(e) {
        if (props.chRef.addWorkExperiece) props.chRef.addWorkExperiece(e);
        // handleClose();
    }

    function getCompanyList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title,profilePicture',
            // 'orderBy[column]': 'isVerified',
            // 'orderBy[dir]': 'desc',
            with: ['company']
        }
        const data = ProfileDataService.fetchCompanyData(input);
        return data;
    }

    function getDesignationList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title',
            // 'orderBy[column]': 'isVerified',
            // 'orderBy[dir]': 'desc',
            with: ['search']
        }
        const data = ProfileDataService.fetchDesignations(input);
        return data;
    }

    const getWEmodalData= {...props.childWEToCall.editWEValues,companyName,weDesignation,addressLoc,weIndustry,currentCTC: props.childWEToCall.editWEValues.currentCTC || 0};

    // console.log("companyName:", companyName)

    return (
        <WorkExperienceContext.Consumer>
            {({ onEditWEHandler, editSuggesions, industries }) => (
                <>
                    <Button
                        onClick={handleShow}
                        variant="white"
                        className="btn"
                        style={{minWidth:"unset !important"}}
                    >
                        <i className="fa fa-plus fa-2x" style={{color: "#F16823"}}></i>
                    </Button>
                    <Modal className="edit-modals" show={show} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Work Experience Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    Company Name <span className="text-danger">*</span>
                                </Form.Label>
                                <Autosuggest
                                    inputProps={{
                                        autoComplete: "off",
                                        name: "company",
                                        id: "company",
                                        value: companyName,
                                        onChange: (e, { newValue }) => {
                                            setcompanyName(newValue);
                                            // This is the key addition - update company data for manual entries
                                            editSuggesions({
                                                id: 0, 
                                                title: newValue,
                                                profilePicture: ''
                                            }, props.index, "company");
                                        }
                                    }}
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                        async ({ value }) => {
                                            if (value === '') {
                                                setSuggestions([]);
                                                return;
                                            }
                                            try {
                                                const result = await getCompanyList(value)
                                                setSuggestions(
                                                    result.data.data.map((item) => ({
                                                        title: item.title,
                                                        id: item.id,
                                                        is_verified: item.is_verified
                                                    }))
                                                );
                                                console.log('This is ', result.data.data);
                                            } catch (e) {
                                                console.log(e)
                                                setSuggestions([]);
                                            }
                                        }
                                    }

                                    onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                    onSuggetionSelected={(event, { suggestion, method }) => {
                                        if (method === "enter")
                                            event.preventDefault();
                                    }}
                                    getSuggestionValue={(suggestion) => {
                                        if (suggestion.id) {
                                            editSuggesions(suggestion, props.index, "company");
                                            return suggestion.title;
                                        }
                                        return companyName; // This allows manual entries to be saved
                                    }}                                    
                                    renderSuggestion={suggestion => <div>{suggestion.title} {suggestion.is_verified && (<i className="fa fa-check-circle text-green"></i>)}</div>}
                                />
                            </Form.Group>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Designation<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Autosuggest
                                            inputProps={{
                                                autoComplete: "off",
                                                name: "designation",
                                                id: "designation",
                                                value: weDesignation,
                                                onChange: (e, { newValue }) => {
                                                    setweDesignation(newValue);
                                                    // Add this to handle manual entries
                                                    editSuggesions({
                                                        id: 0,
                                                        title: newValue
                                                    }, props.index, "designation");
                                                }
                                            }}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={
                                                async ({ value }) => {
                                                    if (value === '') {
                                                        setSuggestions([]);
                                                        return;
                                                    }
                                                    try {
                                                        const result = await getDesignationList(value);
                                                        console.log('This is ', result.data.data);
                                                        setSuggestions(
                                                            result.data.data.map((item) => ({
                                                                title: item.title,
                                                                id: item.id
                                                            }))
                                                        );
                                                    } catch (e) {
                                                        console.log(e)
                                                        setSuggestions([]);
                                                    }
                                                }
                                            }

                                            onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                            onSuggetionSelected={(event, { suggestion, method }) => {
                                                if (method === "enter")
                                                    event.preventDefault();
                                            }}
                                            getSuggestionValue={(suggestion) => {
                                                editSuggesions(suggestion, props.index, "designation");
                                                return suggestion.title
                                            }}
                                            renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Location<span className="text-danger">*</span>
                                        </Form.Label>
                                        <PlacesAutocomplete

                                            autocomplete="off"
                                            value={addressLoc}
                                            onChange={value => {
                                                setAddressLoc(value);
                                                // Store the selected location value
                                                editSuggesions(value, props.index, "location");
                                            }}
                                            onSelect={value => {
                                                setAddressLoc(value);
                                                editSuggesions(value, props.index, "location");
                                            }}
                                        >
                                            {placeGoogle}
                                        </PlacesAutocomplete>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Industry<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Autosuggest
                                            inputProps={{
                                                placeholder: "Industry",
                                                autoComplete: "off",
                                                name: "industry",
                                                id: "industry",
                                                value: weIndustry,
                                                onChange: (e, { newValue }) => {
                                                    setweIndustry(newValue)
                                                }
                                            }}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={
                                                async ({ value }) => {
                                                    if (value === '') {
                                                        setSuggestions([]);
                                                        return;
                                                    }
                                                    try {
                                                        const result = industries ? industries : [];
                                                        const inputValue = value.trim().toLowerCase();
                                                        const inputLength = inputValue.length;
                                                        const filterIndustry = inputLength === 0 ? [] : result.filter(res =>
                                                            res.title.toLowerCase().slice(0, inputLength) === inputValue
                                                        );
                                                        // console.log("filterIndustry::", filterIndustry)
                                                        setSuggestions(filterIndustry);
                                                    } catch (e) {
                                                        console.log(e)
                                                        setSuggestions([]);
                                                    }
                                                }
                                            }

                                            onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                            onSuggetionSelected={(event, { suggestion, method }) => {
                                                if (method === "enter")
                                                    event.preventDefault();
                                            }}
                                            getSuggestionValue={(suggestion) => {
                                                editSuggesions(suggestion, props.index, "industry");
                                                return suggestion.title
                                            }}
                                            renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Job type<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            defaultValue={"1"}
                                            custom
                                            name="job_type"
                                            id="job_type"
                                            onChange={props.childWEToCall.onChangeWEHandler}
                                            value={props.childWEToCall.editWEValues.job_type || "1"}
                                        >
                                            <option value="1">Full time</option>
                                            <option value="2">Part time</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                        CTC / Annual Income (₹ in Lakhs)<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="currentCTC"
                                            id="currentCTC"
                                            min={0}
                                            step={0.1}
                                            onChange={props.childWEToCall.onChangeWEHandler}
                                            value={(props.childWEToCall.editWEValues.currentCTC) || ''}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-0 mt-3">
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="ctc_visible"
                                                    name="ctc_visible"
                                                    label="Hide CTC/Annual Income"
                                                    onChange={props.childWEToCall.onChangeWEHandler}
                                                    checked={props.childWEToCall.editWEValues.ctc_visible} />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start date<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={props.childWEToCall.onChangeWEHandler}
                                            value={props.childWEToCall.editWEValues.durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="end-date">
                                        <Form.Label>End date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={props.childWEToCall.editWEValues.isOnGoing ? true : false}
                                            onChange={props.childWEToCall.onChangeWEHandler}
                                            value={props.childWEToCall.editWEValues.durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="end-date">
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="I currently work here"
                                                    onChange={props.childWEToCall.onChangeWEHandler}
                                                    checked={props.childWEToCall.editWEValues.isOnGoing || false} />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                            {props.childWEToCall.editWEValues.isOnGoing && (
                            <Row className="d-flex align-items-end">
                                <Col md={8}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Work Email ID</Form.Label>
                                        <Form.Control
                                            type="email"
                                            id="email"
                                            name="email"
                                            onChange={props.childWEToCall.onChangeWEHandler}
                                            value={props.childWEToCall.editWEValues.email || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="mt-4">
                                    <Button className="btn-verify mb-3" type="button" variant="outline-success" name="email_verification_status"
                                    onClick={props.childWEToCall.editWEValues.email_verification_status === 0 ? props.childWEToCall.onChangeWEHandler : () => false }>
                                        VERIFY
                                    </Button>
                                </Col>
                            </Row>

                            )}
                            <Form.Group>
                                <Form.Label>
                                    Description<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    name="description"
                                    id="description"
                                    onChange={props.childWEToCall.onChangeWEHandler}
                                    value={props.childWEToCall.editWEValues.description || ""}
                                ></Form.Control>
                            </Form.Group>
                            <div className="text-center pt-3">
                                <WorkExperienceSave closeMain={handleClose} clickThis={onSave} WEmodalData={getWEmodalData} clearWEData={clearWEData}/>
                            </div>

                        </Modal.Body>
                    </Modal>
                </>
            )}
        </WorkExperienceContext.Consumer>
    );
}
