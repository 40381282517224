/* ----------  Imports  ---------- */

// Lodash
import { truncate } from "lodash";

/* ----------  Utils  ---------- */

const Utils = {
	getUserType: userType => {
		switch (parseInt(userType)) {
			case 1: return 'user';
			case 2: return 'company';
			case 3: return 'institute';
			default: return null;
		}
	},

	getUserProfilePhotoUrl: (profilePhoto, userType = 1) => {
		const type = Utils.getUserType(userType);
		if(!profilePhoto) return `/assets/images/default-avatars/default-${ type }.png`;
		return `${ process.env.REACT_APP_API_PUBLIC_URL }/${ type }/images/${ profilePhoto }`;
	},

	truncateText: (text, length = 100, omission = '...') => truncate(text, { length, omission }),

	titleCase: (str) => {
		str = str.toLowerCase().split(' ');
		for (var i = 0; i < str.length; i++) {
		  str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
		}
		return str.join(' ');
	},
	formatNumber:(number) =>{
		let num = parseFloat(number);
		if (num % 1 === 0) {
			return num.toString();
		} else {
			return num.toFixed(2).replace(/\.00$/, '').replace(/\.0$/, '');
		}
	}
}

/* ----------  Export  ---------- */
export default Utils;